import style from './Contacts.module.scss'
import { YMaps, Map, Placemark, Clusterer } from "react-yandex-maps";
import { ContactsMapData } from './ContacstMapData';
import React, { useState, useRef, useEffect } from 'react';
import BreadCrumbs, { IBreadCrumb } from '../../share/BreadCrumbs/BreadCrumbs';
import Container from '../../UI/Container/Container';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';

interface IPlacemarksCollection {
  id: number,
  geometry: number[],
  balloonContent: string
}

const Contacts: React.FC = () => {
  const path: IBreadCrumb[] = [{ name: 'Главная', link: '/' },
  { name: 'Контакты', link: '/contacts' }];

  const [center, setCenter] = useState<number[]>([51.665944, 39.191717]);
  const zoom = 10;
  const map = useRef<HTMLDivElement>(null) as any

  const placeMarkCollection: IPlacemarksCollection[] = [];

  const placeMarksMarkup = ContactsMapData.map(item => {
    placeMarkCollection.push({
      id: item.id,
      geometry: item.geometry,
      balloonContent: `<div style="padding: 20px"><strong style="font-size: 120%">${item.title}</strong> ${item.text}</div>`
    })
    return <Placemark key={item.id} geometry={item.geometry} properties={
      {
        hintContent: item.title,
        balloonContentHeader: item.title,
        balloonContentBody: item.text,
        clusterCaption: `<strong>${item.title}</strong>`
      }}
      options={{
        preset: 'islands#dotIcon',
      }}
    />
  })

  const centerToPoint = (geometry: number[]): void => {
    setCenter(geometry);

    let contentOfBalloon = null;

    placeMarkCollection.forEach(item => {
      if (item.geometry == geometry) {
        contentOfBalloon = item.balloonContent
      }
    })
    map.current.balloon.open(geometry, { content: contentOfBalloon })
  }

  useEffect(() => {
    if (map.current) {
      map.current.panTo(center);
    }
  }, [center])

  useEffect(() => {
    setDocumentTitle('Контакты')
  }, [])

  return (
    <main>
      <Container>
        <BreadCrumbs links={path} />
        <div className={style.wrapper}>
          <h2>Схема проезда</h2>
        </div>
      </Container>
      <section id={style.map}>
        <YMaps>
          <Map defaultState={{ center: center, zoom: zoom }} width='100%' height='100%'
            modules={['geoObject.addon.balloon', 'geoObject.addon.hint', 'clusterer.addon.balloon']}
            instanceRef={map}
          >
            <Clusterer
              options={{
                groupByCoordinates: false,
                clusterDisableClickZoom: true,
                clusterHideIconOnBalloonOpen: false,
                geoObjectHideIconOnBalloonOpen: false
              }}>
              {placeMarksMarkup}
            </Clusterer>
          </Map>
        </YMaps>
      </section>
      <Container className={style.wrapper}>
        <h2>Справочные телефоны</h2>
        <div className={style.contactItemsWrapper}>
          <div className={`${style.contactItem} ${style.contactPhones}`}>
            <h3>Приемная депутата <br />(г. Москва)</h3>
            <p>тел. (495) 692-62-41</p>
            <a href="mailto:chizhov@duma.gov.ru">chizhov@duma.gov.ru</a>
          </div>
          <div className={`${style.contactItem} ${style.contactPhones}`}>
            <h3>Пресс-служба <br />(г. Воронеж)</h3>
            <p>тел. (473) 239-09-68</p>
            <a href="mailto:36glch@gmail.com">36glch@gmail.com</a> <br />
            <a href="mailto:pressglch@gmail.com">pressglch@gmail.com</a>
          </div>
          <div className={`${style.contactItem} ${style.contactPhones}`}>
            <h3>Контактный центр <br />(г. Воронеж)</h3>
            <p>тел. (473) 261-99-99</p>
          </div>
        </div>
        <h2>Общественные приемные</h2>
        <section className={style.contactItemsWrapper}>
          {ContactsMapData.map(item => {
            return <a key={item.id} href={`#${style.map}`} className={style.contactItem} onClick={() => centerToPoint(item.geometry)}>
              <h3>{item.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: item.text }}></div>
            </a>
          })}
        </section>
      </Container>
    </main>
  )
}
export default Contacts;