export const initPagination = (numberOfPages: number, activePage: number, isMobile: boolean = false) => {
  const paginationItems: number[] = [];

  const pagesToShow = isMobile ? 5 : 10;

  if (numberOfPages <= pagesToShow) {
    for (let i = 1; i <= numberOfPages; i++) {
      paginationItems.push(i)
    }
  }
  else {
    if (activePage >= numberOfPages - Math.floor(pagesToShow / 2) + 1) {
      for (let i = numberOfPages - pagesToShow + 1; i <= numberOfPages; i++) {
        if (i > 0) {
          paginationItems.push(i)
        }
      }
    }
    else if (activePage > Math.floor(pagesToShow / 2)) {
      for (let i = activePage - Math.ceil(pagesToShow / 2) + 1; i <= activePage + Math.floor(pagesToShow / 2); i++) {
        paginationItems.push(i)
      }
    }
    else {
      for (let i = 1; i <= pagesToShow; i++) {
        paginationItems.push(i)
      }
    }
  }
  return paginationItems;
}