import FeedbackSlider from '../../share/FeedBackSlider/FeedbackSlider';
import style from './Feedback.module.scss'
import { useEffect } from 'react'
import FeedbackForm from './FeedbackForm/FeedbackForm';
import BreadCrumbs, { IBreadCrumb } from '../../share/BreadCrumbs/BreadCrumbs';
import ReviewSlider from '../../share/ReviewSlider/ReviewSlider';
import Container from '../../UI/Container/Container';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';
import FeedbackSliderSkeleton from '../../share/FeedBackSlider/FeedbackSliderSkeleton/FeedbackSliderSkeleton';
import { useInView } from 'react-intersection-observer';

const Feedback: React.FC = () => {
  const path: IBreadCrumb[] = [{ name: 'Главная', link: '/' },
  { name: 'Отзывы', link: '/feedback' }]

  useEffect(() => {
    setDocumentTitle('Отзывы')
  }, [])

  const { ref, inView } = useInView({
    rootMargin: '5px',
    triggerOnce: true
  })

  return (
    <Container className={style.wrapper}>
      <BreadCrumbs links={path} />
      <section className={style.sliderWrapper}>
        <h2>СМИ о нас</h2>
        <ReviewSlider type={2} />
      </section>
      <section className={style.section} ref={ref}>
        <h2>Отзывы наших посетителей</h2>
        {
          inView ? <FeedbackSlider />
            : <FeedbackSliderSkeleton />
        }
      </section>
      <section className={style.section}>
        <h2>Хотите оставить отзыв?</h2>
        <div className={style.formWrapper}>
          <h3>Оставить отзыв легко!</h3>
          <p>заполните несколько простых полей, а мы опубликуем ваш отзыв у нас на сайте.</p>
          <FeedbackForm />
        </div>
      </section>
    </Container>
  )
}

export default Feedback;