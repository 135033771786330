import { useEffect, useRef, useState } from 'react';
import style from './NewsItem.module.scss'
import { Link } from 'react-router-dom';
import share from '../../../assets/images/icons/share.png'
import { BASE_URL } from '../../../utils/services';

interface NewsProps {
  image: string,
  slug: string,
  title: string,
  date: string,
  descriptionClass?: string,
  wrapperClass?: string,
  linkClass?: string
}

const NewsItem: React.FC<NewsProps> = ({ image, slug, title, date, wrapperClass, descriptionClass, linkClass }) => {
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const socialRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (socialRef.current) {
      //@ts-ignore
      Ya.share2(socialRef.current, {
        content: {
          url: `https://priyomnaya.chizhov-s-v.ru/news/view/${slug}`,
          title: title,
          image: `${BASE_URL}${image}`
        },
        theme: {
          services: 'vkontakte,odnoklassniki',
          lang: 'ru',
          bare: false,
        },
      });
    }
  }, [socialRef, slug, image, title])

  return (
    <div className={wrapperClass ? wrapperClass : style.wrapper}>
      <Link to={`/news/view/${slug}`} className={linkClass ? linkClass : style.imageLink}>
        <img src={`${BASE_URL}${image}`} alt="" width="100%" />
      </Link>
      <div className={descriptionClass ? descriptionClass : style.description}>
        <Link to={`/news/view/${slug}`}>
          {title}
        </Link>
        <p className={style.date}>{date}</p>
        <div className={style.social} style={{ display: shareOpen ? 'block' : 'none' }}>
          <div ref={socialRef}></div>
        </div>
        <button type='button' className={style.share} onClick={() => setShareOpen(prev => !prev)}>
          <img src={share} alt="share" width="100%" />
        </button>
      </div>
    </div>
  )
}

export default NewsItem;