import { FieldError } from 'react-hook-form';
import { classNames } from '../../../utils/classNames/classNames';
import style from './TextArea.module.scss'
import { TextareaHTMLAttributes, forwardRef } from 'react';
import ErrorText from '../ErrorText/ErrorText';

export enum TextAreaSize {
  S = 'size_s',
  M = 'size_m'
}

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  size?: TextAreaSize
  wrapperClassName?: string
  error?: FieldError
  touched?: boolean
  className?: string
  id?: string
  label?: string
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const {
    size = TextAreaSize.S,
    wrapperClassName = '',
    className = '',
    error,
    touched,
    id,
    label
  } = props;

  const additional = [
    style[size],
    className
  ]

  return (
    <div className={classNames(style.textArea, {}, [wrapperClassName])}
      data-status={error ? 'error' : touched ? 'success' : ''}
    >
      <label htmlFor={id} className={style.label}>{label}</label>
      <textarea
        className={classNames(style.textField, {}, additional)}
        {...props}
        ref={ref}
      />
      {error?.message && <ErrorText>{error?.message}</ErrorText>}
    </div>
  )
})

export default TextArea;