import style from './Gallery.module.scss'
import { BASE_URL } from '../../../utils/services';
import Portal from '../Portal/Portal';
import { classNames } from '../../../utils/classNames/classNames';
import { IGalleryItem } from '../../../types/apiInterfaces';

interface GalleryProps {
  isActive: boolean
  galleryItems: IGalleryItem[]
  activeImage: number
  setActiveImage: (index: number) => void
  onClose: () => void
}

const Gallery: React.FC<GalleryProps> = (props) => {
  const {
    galleryItems,
    isActive,
    activeImage,
    onClose,
    setActiveImage
  } = props;

  const prevImg = () => {
    let newImageIndex = activeImage === 0 ? galleryItems.length - 1 : activeImage - 1;
    setActiveImage(newImageIndex)
  }

  const nextImg = () => {
    let newImageIndex = activeImage === galleryItems.length - 1 ? 0 : activeImage + 1;
    setActiveImage(newImageIndex)
  }

  return <Portal>
    <div className={classNames(style.overlay, { [style.active]: isActive }, [])} onClick={onClose}>
      <div tabIndex={-1} className={style.imgWrapper} onClick={e => e.stopPropagation()}>
        <button type='button' className={style.close} onClick={onClose} aria-label='Закрыть галерею' />
        <img src={`${BASE_URL}${galleryItems[activeImage].path}`} />

        <button type='button' className={classNames(style.slideButton, {}, [style.prev])} onClick={prevImg}>
          <div className={classNames(style.buttonIcon, {}, [style.prevIcon])} aria-label='Предыдущее изображение' />
        </button>
        <button type='button' className={classNames(style.slideButton, {}, [style.next])} onClick={nextImg}>
          <div className={classNames(style.buttonIcon, {}, [style.nextIcon])} aria-label='Следующее изображение' />
        </button>
      </div>
    </div>
  </Portal>
}

export default Gallery;