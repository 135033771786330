import style from './HomeReviewBlock.module.scss'
import { Link } from 'react-router-dom';
import ReviewSlider from '../../../share/ReviewSlider/ReviewSlider';
import Button from '../../../UI/Button/Button';
import { useInView } from 'react-intersection-observer';
import ReviewSliderSkeleton from '../../../share/ReviewSlider/ReviewSliderSkeleton/ReviewSliderSkeleton';

const HomeReviewBlock: React.FC = () => {

  const { ref, inView } = useInView({
    rootMargin: '5px',
    triggerOnce: true
  })

  return (
    <section className={style.sliderWrapper} ref={ref}>
      <h3 className={style.title}>СМИ о нас</h3>
      {
        inView ? <ReviewSlider type={1} /> : <ReviewSliderSkeleton type={1} />
      }
      <Link to="/feedback" className={style.linkWrapper}>
        <Button>
          Оставить отзыв
        </Button>
      </Link>
    </section>
  )
}

export default HomeReviewBlock;