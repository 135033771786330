import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../utils/services';
import style from './SingleFeedback.module.scss'
import { useEffect } from 'react'
import BreadCrumbs from '../../share/BreadCrumbs/BreadCrumbs';
import Container from '../../UI/Container/Container';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';
import Title from '../../UI/Title/Title';
import { useQuery } from 'react-query';
import { getData } from '../../../utils/services';
import { IReview } from '../../../types/apiInterfaces';
import SinglePageSkeleton from '../../share/SinglePageSkeleton/SinglePageSkeleton';

const FeedbackItem: React.FC = () => {
  const slug = useParams().id;

  const { data, isLoading } = useQuery({
    queryKey: ['shingle-review', slug],
    queryFn: (): Promise<IReview> => getData(`review/get-review/${slug}`)
  })

  useEffect(() => {
    if (data?.title) {
      setDocumentTitle(data.title)
    }
  }, [data])

  return <Container>
    <BreadCrumbs links={[{ name: 'Главная', link: '/' },
    { name: 'Отзывы', link: '/feedback' }, { name: '', link: '' }]} />
    {isLoading ? <SinglePageSkeleton /> :
      <>
        <Title>{data?.name}</Title>
        <div className={style.imgWrapper}>
          <img src={`${BASE_URL}${data?.imagePath}`} alt="Фото" />
        </div>
        <div className={style.description}
          dangerouslySetInnerHTML={{ __html: data?.text ?? '' }}>
        </div>
      </>
    }
  </Container>

}

export default FeedbackItem;