import style from './Footer.module.scss'
import duma from '../../../assets/images/duma.png'
import gerb from '../../../assets/images/gerb.png'
import vk from '../../../assets/images/icons/vk.svg'
import { Link } from 'react-router-dom'
import FooterForm from './FooterForm/FooterForm'
import FooterSection from './FooterSection/FooterSection'

const year = new Date().getFullYear();

const Footer: React.FC = () => {

  return (
    <footer className={style.footer}>
      <div className={style.items}>

        <FooterSection title='Разделы сайта'>
          <Link to="/question-to-lawyer" className={style.navlink}>Вопрос юристу</Link>
          <Link to="/about" className={style.navlink}>О нас</Link>
          <Link to="/feedback" className={style.navlink}>Отзывы</Link>
          <Link to="/news" className={style.navlink}>Новости</Link>
          <Link to="/contacts" className={style.navlink}>Контакты</Link>
        </FooterSection>

        <div className={style.column}>
          <FooterSection title='Контактные данные'>
            Приемная депутата (г. Москва) <br />
            <span className={style.smallText}>тел. (495) 692-62-41</span> <br />
            <a href="mailto:chizhov@duma.gov.ru" className={style.link}>chizhov@duma.gov.ru</a> <br />
            <br />
            Пресс-служба (г. Воронеж) <br />
            <span className={style.smallText}>тел. (473) 239-09-68</span> <br />
            <a href="mailto:36glch@gmail.com" className={style.link}>36glch@gmail.com</a> <br />
            <a href="mailto:pressglch@gmail.com" className={style.link}>pressglch@gmail.com</a><br />
            <br />
            Контактный центр (г. Воронеж) <br />
            <span className={style.smallText}>тел. (473) 261-99-99</span><br />
            <a href="mailto:call-center@glch.ru" className={style.link}>call-center@glch.ru</a>
          </FooterSection>

          <FooterSection title='Мы в социальных сетях'>
            <a href="https://vk.com/chizhov.priyomnaya" className={style.social} target="_blank">
              <img src={vk} alt="вк" height='100%' />
            </a>
          </FooterSection>
        </div>

        <div className={style.column}>
          <FooterSection title='Оформить подписку'>
            Подпишитесь чтобы получать <br />
            извещения о публикации новостей
            <FooterForm />
          </FooterSection>

          <FooterSection title='Личные страницы С. В. Чижова'>
            <a href="https://vk.com/chizhovsv" className={style.social} target="_blank">
              <img src={vk} alt="вк" height='100%' />
            </a>
          </FooterSection>
        </div>

      </div>

      <div className={style.hr}></div>

      <div className={style.bottom}>
        <img src={duma} alt="дума" />
        <div className={style.bottomText}>
          <p>Онлайн Приемная</p>
          <p>Депутата Государственной Думы Сергея Викторовича Чижова, {year} г.</p>
        </div>
        <img src={gerb} alt="герб" />
      </div>
    </footer >
  )
}

export default Footer;