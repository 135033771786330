import { classNames } from '../../../utils/classNames/classNames';
import style from './SkeletonPlaceholder.module.scss'

interface SkeletonPlaceholderProps {
  className?: string,
}

const SkeletonPlaceholder: React.FC<SkeletonPlaceholderProps> = ({ className }) => {
  return <div data-testid="skeleton-placeholder"
    className={classNames(style.wrapper, {}, [className ?? ''])}>
    <div className={style.activity} />
  </div>
}

export default SkeletonPlaceholder;