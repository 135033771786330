import style from './Header.module.scss'
import logo1 from '../../../assets/images/logo1.png'
import logo2 from '../../../assets/images/logo2.png'
import { Link, NavLink } from 'react-router-dom'
import { useState } from 'react'
import MobileMenu from '../MobileMenu/MobileMenu'

const Header: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const closeBurger = (): void => {
    document.body.style.overflow = 'auto'
    setOpen(false)
  }

  const toggleBurger = (): void => {
    if (open) {
      closeBurger();
    }
    else {
      document.body.style.overflow = 'hidden';
      setOpen(true)
    }
  }

  return (
    <header className={style.header}>
      <nav className={style.container}>
        <div className={style.logoWrapper}>
          <Link className={style.logo} to="/" onClick={closeBurger}>
            <img src={logo1} className={style.logo1} alt="дума" />
            <img src={logo2} className={style.logo2} alt="" />
          </Link>
        </div>
        <ul className={style.nav}>
          <li>
            <NavLink to="/question-to-lawyer" className={({ isActive }) => isActive ? style.activeLink : style.link}>Вопрос юристу</NavLink>
            <ul className={`${style.innerList} ${style.innerList1}`}>
              <li><Link to="/faq" className={style.link}>Часто задаваемые вопросы</Link></li>
            </ul>
          </li>
          <li>
            <NavLink to="/about" className={({ isActive }) => isActive ? style.activeLink : style.link}>О нас</NavLink>
            <ul className={`${style.innerList} ${style.innerList2}`}>
              <li><Link to="/job" className={style.link}>Вакансии</Link></li>
            </ul>
          </li>
          <li><NavLink to="/news" className={({ isActive }) => isActive ? style.activeLink : style.link}>Новости</NavLink></li>
          <li>
            <NavLink to="/feedback" className={({ isActive }) => isActive ? style.activeLink : style.link}>Отзывы</NavLink>
            <ul className={`${style.innerList} ${style.innerList3}`}>
              <li><Link to="/thanks" className={style.link}>Благодарности</Link></li>
            </ul>
          </li>
          <li><NavLink to="/contacts" className={({ isActive }) => isActive ? style.activeLink : style.link}>Контакты</NavLink></li>
        </ul>
        <button className={`${style.burger} ${open ? style.active : ''}`} type="button" onClick={toggleBurger}>
          <span className={style.burgerLine}></span>
          <span className={style.burgerLine}></span>
        </button>
      </nav>
      <MobileMenu open={open} closeBurger={closeBurger} />
    </header>
  )
}

export default Header;