import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Outlet } from 'react-router-dom'
import UpButton from "./UpButton/UpButton";
import LawyerQuestionButton from "./LawyerQuestionButton/LawyerQuestionButton";
import style from './Layout.module.scss'

const Layout: React.FC = () => {
  const isTablet = window.matchMedia('(max-width: 992px)').matches;

  return (
    <>
      {
        !isTablet &&
        <>
          <UpButton />
          <LawyerQuestionButton />
        </>
      }
      <Header />
      <main className={style.main}>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

export default Layout;