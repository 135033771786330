import { classNames } from '../../../utils/classNames/classNames';
import Portal from '../../share/Portal/Portal';
import style from './MobileMenu.module.scss'
import { Link } from 'react-router-dom';

interface MobileMenuProps {
  open: boolean,
  closeBurger: () => void
}

const MobileMenu: React.FC<MobileMenuProps> = ({ open, closeBurger }) => {

  return (
    <Portal>
      <div className={classNames(style.wrapper, { [style.active]: open })}>
        <ul>
          <li>
            <Link to="/question-to-lawyer" className={style.link} tabIndex={-1} onClick={closeBurger}>Вопрос юристу</Link>
            <ul className={`${style.innerList} ${style.innerList1}`}>
              <li><Link to="/faq" className={style.link} tabIndex={-1} onClick={closeBurger}>Часто задаваемые вопросы</Link></li>
            </ul>
          </li>
          <li>
            <Link to="/about" className={style.link} tabIndex={-1} onClick={closeBurger}>О нас</Link>
            <ul className={`${style.innerList} ${style.innerList2}`}>
              <li><Link to="/job" className={style.link} tabIndex={-1} onClick={closeBurger}>Вакансии</Link></li>
            </ul>
          </li>
          <li><Link to="/news" className={style.link} tabIndex={-1} onClick={closeBurger}>Новости</Link></li>
          <li>
            <Link to="/feedback" className={style.link} tabIndex={-1} onClick={closeBurger}>Отзывы</Link>
            <ul className={`${style.innerList} ${style.innerList3}`}>
              <li><Link to="/thanks" className={style.link} tabIndex={-1} onClick={closeBurger}>Благодарности</Link></li>
            </ul>
          </li>
          <li><Link to="/contacts" className={style.link} tabIndex={-1} onClick={closeBurger}>Контакты</Link></li>
        </ul>
      </div>
    </Portal>
  )
}

export default MobileMenu;