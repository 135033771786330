const month = ["янв.", "февр.", "мар.", "апр.", "мая", "июн.", "июл.", "авг.", "сент.", "окт.", "нояб.", "дек."] as const;

export const formatPassedTime = (createdAt: number, timeDifference?: number): string => {
  const date = new Date(createdAt * 1000);
  const dateFormatted = `${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()} г.`

  if (timeDifference) {
    let hour = Math.floor(timeDifference / 60 / 60);
    let minute = Math.ceil(timeDifference / 60);

    if (hour) {
      if (hour >= 24) return dateFormatted;
      if (hour < 20 && hour > 10) {
        return `${hour} часов назад`
      }
      if (hour % 10 > 1 && hour % 10 < 5) {
        return `${hour} часа назад`
      }
      if (hour % 10 === 1) {
        return `${hour} час назад`
      }
      return `${hour} часов назад`
    }

    if (minute > 10 && minute < 20) {
      return `${minute} минут назад`
    }
    if (minute % 10 > 1 && minute % 10 < 5) {
      return `${minute} минуты назад`
    }
    if (minute % 10 === 1) {
      return `${minute} минуту назад`
    }
    return `${minute} минут назад`
  }

  return dateFormatted;
}
