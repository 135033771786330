import { useEffect, } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs, { IBreadCrumb } from '../../share/BreadCrumbs/BreadCrumbs';
import style from './LawyerQuestion.module.scss'
import LawyerQuestionForm from './LawyerQuestionForm/LawyerQuestionForm';
import Button from '../../UI/Button/Button';
import Container from '../../UI/Container/Container';
import Title from '../../UI/Title/Title';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';

const LawyerQuestion: React.FC = () => {
  const path: IBreadCrumb[] = [{ name: 'Главная', link: '/' },
  { name: 'Вопрос юристу', link: '/question-to-lawyer' }];

  useEffect(() => {
    setDocumentTitle('Вопрос юристу')
  }, [])

  return (
    <Container>
      <BreadCrumbs links={path} />
      <Title>Вопросы юристу</Title>
      <div className={style.wrapper}>
        <div className={style.faq}>
          <h2>Часто задаваемые вопросы</h2>
          <p>В отдельном разделе мы ведем архив с ответами на задаваемые нашим юристам вопросы. Ознакомьтесь с архивом, чтобы найти ответ на наш вопрос или задайте вопрос лично.</p>
          <Link to="/faq" className={style.blueButton}>
            <Button>Перейти в архив</Button>
          </Link>
          <h2>Как задать вопрос?</h2>
          <p>Чтобы задать вопрос введите контактые данные, расскажите о проблеме и отправьте заявку. Наш юрист ответит вам в течении 60 минут.</p>
        </div>
        <div className={style.form}>
          <LawyerQuestionForm />
        </div>
      </div>
    </Container>
  )
}

export default LawyerQuestion;