import Button from '../../../UI/Button/Button';
import style from './HomeFirstBlock.module.scss'
import { Link } from 'react-router-dom';

const HomeFirstBlock: React.FC = () => {
  return (
    <div className={style.description}>
      <div className={style.text}>
        <h1 className={style.title}>Общественная приемная С. В. Чижова</h1>
        <p>Наша миссия — оказать каждому бесплатную и качественную юридическую помощь</p>
      </div>
      <div className={style.video}>
        <iframe width="100%"
          className={style.iframe}
          title='Депутат Госдумы помогает воронежцам в решении социально-правовых вопросов'
          height="100%"
          src="https://www.youtube.com/embed/r7ZsHaI-mZM"
          frameBorder={0}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      </div>
      <div className={style.count}>
        <p className={style.number}>12 919</p>
        <p>обращений за 2020</p>
        <p className={style.number}>10 443</p>
        <p>обращения за 2021</p>
        <Link to="/faq" className={style.faqBtn}>
          <Button>
            Подробнее
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default HomeFirstBlock;