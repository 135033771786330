import { ReactNode } from 'react';
import { classNames } from '../../../utils/classNames/classNames';
import style from './ErrorText.module.scss'

interface ErrorTextProps {
  className?: string,
  children?: ReactNode
}

const ErrorText: React.FC<ErrorTextProps> = ({ className = '', children }) => {
  return (
    <p className={classNames(style.errorText, {}, [className])}>
      {children}
    </p>
  )
}

export default ErrorText;