export default function createSearchParamsString(searchObject: Record<string, string | string[] | undefined | null> | undefined): string {
  if (!searchObject) return ''
  if (typeof searchObject !== 'object') return ''
  return Object.entries(searchObject)
    .filter(([_, value]) => Boolean(value))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return (value as string[]).map(valueItem => {
          return key.includes('[]') ? `${key}=${valueItem}` : `${key}[]=${valueItem}`
        })
          .join("&")
      }
      return `${key}=${value}`
    }).join("&")
}