import { ReactNode, useState } from 'react';
import style from './FooterSection.module.scss'
import { classNames } from '../../../../utils/classNames/classNames';

interface FooterSectionProps {
  title: string,
  children: ReactNode
}

const FooterSection: React.FC<FooterSectionProps> = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleContent = () => {
    setIsOpen(pr => !pr)
  }

  return (
    <div className={style.footerSection}>
      <h3 className={style.title} onClick={toggleContent}>
        {title}
        <span className={style.caret}></span>
      </h3>
      <div className={classNames(style.innerContent, { [style.open]: isOpen })}>
        <div style={{ minHeight: 0 }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default FooterSection;