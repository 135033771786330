import style from './ThanksItem.module.scss'
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import BreadCrumbs from '../../share/BreadCrumbs/BreadCrumbs'
import Pagination from '../../share/Pagination/Pagination'
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle'
import Gallery from '../../share/Gallery/Gallery'
import { useQuery } from 'react-query'
import { ApiDataResponseType, IGalleryItem } from '../../../types/apiInterfaces'
import { getData } from '../../../utils/services'
import ThanksList from './ThanksList/ThanksList'
import ThanksListSkeleton from './ThanksList/ThanksListSkeleton'
import Container from '../../UI/Container/Container'
import Title from '../../UI/Title/Title'

const ThanksItem: React.FC = () => {
  const slug = useParams().id;

  const [selectedImage, setSelectedImage] = useState(0);
  const [isGalleryOpen, setGalleryOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get('page') || '1';

  const changePage = (page: number) => {
    if (page === 1) searchParams.delete('page');
    else searchParams.set('page', String(page));
    setSearchParams(searchParams)
  }

  const { data, isLoading } = useQuery({
    queryKey: ['galleryItem', page],
    queryFn: (): Promise<ApiDataResponseType<IGalleryItem[]>> => getData('gallery/images/', {
      models: false,
      searchParams: {
        page: page,
        slug: slug
      }
    })
  })

  const images = data?.models ?? [];

  useEffect(() => {
    if (slug) setDocumentTitle(slug)
  }, [slug])

  const openGallery = (index: number) => {
    setGalleryOpen(true)
    setSelectedImage(index)
  }

  const closeGallery = () => {
    setGalleryOpen(false)
  }

  return (
    <Container>
      <BreadCrumbs links={[{ name: 'Главная', link: '/' },
      { name: 'Благодарности', link: '/thanks' }, { name: slug ?? '', link: '' }]} />
      <Title>Благодарности</Title>
      <p className={style.year}>{slug}</p>
      {
        isLoading ?
          <ThanksListSkeleton /> :
          <>
            <Gallery
              activeImage={selectedImage}
              galleryItems={images}
              isActive={isGalleryOpen}
              onClose={closeGallery}
              setActiveImage={setSelectedImage}
            />
            <ThanksList items={images} openGallery={openGallery} />
            {data?.count && data?.count > 1 ?
              <Pagination
                activePage={Number(page)}
                setActivePage={changePage}
                numberOfPages={data.count}
              />
              : null}
          </>
      }
    </Container>
  )
}

export default ThanksItem;