import style from './Thanks.module.scss'
import { Link } from 'react-router-dom';
import BreadCrumbs, { IBreadCrumb } from '../../share/BreadCrumbs/BreadCrumbs';
import { useEffect } from 'react';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';
import { IGallery } from '../../../types/apiInterfaces';
import { useQuery } from 'react-query';
import { getData } from '../../../utils/services';
import SkeletonPlaceholder from '../../UI/SkeletonPlaceholder/SkeletonPlaceholder';
import Container from '../../UI/Container/Container';
import Title from '../../UI/Title/Title';

const Thanks: React.FC = () => {
  const path: IBreadCrumb[] = [{ name: 'Главная', link: '/' },
  { name: 'Благодарности', link: '/thanks' }]

  const { data, isLoading } = useQuery({
    queryKey: ['gallery'],
    queryFn: (): Promise<IGallery[]> => getData('gallery')
  })

  useEffect(() => {
    setDocumentTitle('Благодарности')
  }, [])

  return (
    <Container>
      <BreadCrumbs links={path} />
      <Title>Благодарности</Title>
      <div className={style.thanksContainer}>
        {
          isLoading ?
            <>
              <SkeletonPlaceholder className={style.itemPlaceholder} />
              <SkeletonPlaceholder className={style.itemPlaceholder} />
              <SkeletonPlaceholder className={style.itemPlaceholder} />
            </> :
            data?.map(item => <Link key={item.created_at}
              to={`view/${item.slug}`}
              className={style.thanksItem}>
              {item.title}
            </Link>)
        }
      </div>
    </Container>
  )
}

export default Thanks;