import { forwardRef } from 'react';
import style from './Input.module.scss'
import { FieldError } from 'react-hook-form';
import { classNames } from '../../../utils/classNames/classNames';
import InputMask from 'react-input-mask';
import ErrorText from '../ErrorText/ErrorText';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  wrapperClassName?: string
  error?: FieldError
  touched?: boolean
  id?: string
  mask?: string
  label?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    error, touched, label,
    id, mask, className = '',
    wrapperClassName = '',
    type = 'text', ...otherProps
  } = props;

  return (
    <div className={classNames(style.wrapper, {}, [wrapperClassName])}
      data-testid="input-wrapper"
      data-status={error ? 'error' : touched ? 'success' : ''}
    >

      {label && <label className={style.label} htmlFor={id}>{label}</label>}

      {mask ?
        <InputMask mask={mask} type={type} className={style.input} {...otherProps} inputRef={ref} />
        :
        <input className={classNames(style.input, {}, [className])}
          type={type}
          id={id}
          ref={ref}
          {...otherProps} />
      }

      {error?.message && <ErrorText>{error?.message}</ErrorText>}
    </div>
  )
})

export default Input;