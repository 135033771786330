import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface IScrollToTop {
  children: React.ReactNode
}

const root = document.querySelector('#root');

export const scrollToTop = () => {
  root?.scrollTo(0, 0);
}

const ScrollToTopWrapper: React.FC<IScrollToTop> = ({ children }) => {
  const location = useLocation();
  
  useEffect(() => {
    scrollToTop();
  }, [location, root]);

  return <>{children}</>
};

export default ScrollToTopWrapper;