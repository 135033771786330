import style from './FeedbackForm.module.scss'
import {useState, useCallback, useEffect} from 'react'
import {FormStatusType, IFeedbackForm} from '../../../../types/formInterfaces';
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';
import {sendFormFeedback} from '../../../../utils/services';
import {useForm} from 'react-hook-form';
import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';
import {classNames} from '../../../../utils/classNames/classNames';
import TextArea from '../../../UI/TextArea/TextArea';
import Select from '../../../UI/Select/Select';
import ErrorText from '../../../UI/ErrorText/ErrorText';
import Loader from '../../../share/Loader/Loader';
import FormSuccess from '../../../share/FormSuccess/FormSuccess';

const FeedbackForm: React.FC = () => {
  const [token, setToken] = useState<string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);
  const [recomendOpen, setRecomendOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<FormStatusType>(null);
  const [isSending, setIsSending] = useState(false)

  const {
    register,
    handleSubmit,
    formState: {errors, touchedFields},
    reset
  } = useForm<IFeedbackForm>({mode: 'onBlur'});

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setRefreshReCaptcha(r => !r);
    }, 120000)
    return () => clearInterval(id)
  }, [])

  const onSubmit = async (data: IFeedbackForm) => {
    try {
      setIsSending(true)
      setStatus(null)
      const response = await sendFormFeedback(data, token ?? '');
      ;
      if (!response.ok) {
        throw new Error();
      }
      setRefreshReCaptcha(r => !r);
      reset();
      setStatus('success');
    } catch (e) {
      setStatus('error')
    } finally {
      setIsSending(false)
    }
  }

  return (
    <>
      <FormSuccess
        title='Отзыв отправлен'
        description='Отзыв отправлен на обработку и модерацию. <br/> Ожидайте публикацию.'
        isSent={status === 'success'}
      />
      <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
        <div className={style.inputGroup}>

          <Input
            id='feedback__name'
            error={errors.name}
            touched={touchedFields.name}
            placeholder='Фамилия Имя'
            {...register('name', {
              required: 'Необходимо заполнить «Фамилия, Имя».'
            })}
          />

          <Input
            id='feedback__contact'
            error={errors.contact}
            touched={touchedFields.contact}
            placeholder='Контактный номер или email'
            {...register('contact', {
              required: 'Необходимо заполнить «Контактный номер или email».'
            })}
          />

          <Select
            error={errors.category}
            touched={touchedFields.category}
            {...register('category', {
              required: false
            })}
          >
            <option value="">Выберите право из списка</option>
            <option value="Власть">Власть</option>
            <option value="Документы">Документы</option>
            <option value="Жильё">Жильё</option>
            <option value="ЖКХ">ЖКХ</option>
            <option value="Имущество">Имущество</option>
            <option value="Льготы, здравоохранение">Льготы, здравоохранение</option>
            <option value="Наследство">Наследство</option>
            <option value="Образование">Образование</option>
            <option value="Пенсии">Пенсии</option>
            <option value="Пособие">Пособие</option>
            <option value="Работа">Работа</option>
            <option value="Семья">Семья</option>
            <option value="Соцобеспечение и соцзащита">Соцобеспечение и соцзащита</option>
            <option value="Суд">Суд</option>
            <option value="Транспорт">Транспорт</option>
            <option value="Юридические">Юридические</option>
          </Select>
        </div>

        <div className={style.feedbackText}>
          <button type='button' className={style.recomendBtn}
                  onClick={() => setRecomendOpen(prev => !prev)}>Рекомендации
          </button>
          <div className={classNames(style.recomendList, {[style.open]: recomendOpen})}>
            <h3>Запрещается:</h3>
            <ul>
              <li>- Публиковать любые материалы, призывающие к нарушению действующего законодательства.</li>
              <li>- Размещать коммерческие материалы (писать рекламные отзывы, оставлять рекламные ссылки).</li>
              <li>- Публиковать отзывы, основанные на собственных домыслах и предположениях, рассказах друзей, знакомых,
                родственников, а не на реальном опыте.
              </li>
              <li>- Публиковать отзывы, состоящие из менее чем трех содержательных предложений.</li>
              <li>- Оскорблять других пользователей и представителей компании.</li>
              <li>- Использовать ненормативную или нецензурную лексику.</li>
              <li>- Нарушать общепринятые правила орфографии или допускать большое количество
                грамматических/орфографических ошибок.
              </li>
              <li>- Публиковать отзывы в ответ на чьи-либо отзывы.</li>
            </ul>
          </div>

          <TextArea
            error={errors.text}
            touched={touchedFields.text}
            placeholder='Ваша оценка нашей работы (максимум 1000 символов)'
            {...register('text', {
              required: 'Необходимо заполнить «Текст».',
              maxLength: {
                value: 1000,
                message: 'Значение «Текст» должно содержать максимум 1 000 символов'
              }
            })}
          />
        </div>

        <GoogleReCaptcha
          action='homepage'
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />

        {status === 'error' &&
            <ErrorText>Произошла ошибка при отправке формы. Попробуйте перезагрузить страницу или отправить форму позже
                снова</ErrorText>
        }

        <Button
          type='submit'
          className={style.buttonSubmit}
          disabled={isSending}
        >
          {
            isSending ?
              <>
                Отправка
                <Loader/>
              </>
              :
              'Оставить отзыв'
          }
        </Button>
      </form>
    </>
  )
}

export default FeedbackForm;
