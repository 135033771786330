import { SelectHTMLAttributes, forwardRef } from 'react';
import style from './Select.module.scss'
import { classNames } from '../../../utils/classNames/classNames';
import { FieldError } from 'react-hook-form';
import ErrorText from '../ErrorText/ErrorText';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  children?: React.ReactNode,
  wrapperClassName?: string,
  error?: FieldError,
  touched?: boolean,
}

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {
    children,
    wrapperClassName = '',
    className = '',
    error,
    touched,
    onChange,
    ...otherProps
  } = props;

  return (
    <div className={classNames(style.wrapper, {}, [wrapperClassName])}
      data-status={error ? 'error' : touched ? 'success' : ''}
    >

      <select
        {...otherProps}
        ref={ref}
        className={classNames(style.select, {}, [className])}
      >
        {children}
      </select>
      {error?.message && <ErrorText>{error?.message}</ErrorText>}
    </div>
  )
})

export default Select;