import style from './NewsItemPage.module.scss'
import { useRef, useEffect } from 'react'
import BreadCrumbs from '../../share/BreadCrumbs/BreadCrumbs';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../utils/services';
import Loader from '../../share/Loader/Loader';
import Container from '../../UI/Container/Container';
import Title from '../../UI/Title/Title';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';
import { useQuery } from 'react-query';
import { getData } from '../../../utils/services';
import { INews } from '../../../types/apiInterfaces';
import SinglePageSkeleton from '../../share/SinglePageSkeleton/SinglePageSkeleton';
import SkeletonPlaceholder from '../../UI/SkeletonPlaceholder/SkeletonPlaceholder';

const NewsItemPage: React.FC = () => {
  const socialRef = useRef<HTMLDivElement | null>(null)
  const slug = useParams().id;

  const { data, isLoading } = useQuery({
    queryKey: ['single-news', slug],
    queryFn: (): Promise<INews> => getData(`news/get-news/${slug}`)
  })

  useEffect(() => {
    if (socialRef.current) {
      //@ts-ignore
      Ya.share2(socialRef.current, {
        content: {
          url: `https://priyomnaya.chizhov-s-v.ru/news/view/${data?.slug}`,
          title: data?.title,
          image: `${BASE_URL}${data?.imagePath}`
        },
        theme: {
          services: 'vkontakte,odnoklassniki',
          direction: 'vertical'
        },
      });
    }
  }, [socialRef, data])

  useEffect(() => {
    if (data?.title) {
      setDocumentTitle(data.title)
    }
  }, [data])

  return (
    <Container>
      <BreadCrumbs links={[{ name: 'Главная', link: '/' },
      { name: 'Новости', link: '/news' }, { name: '', link: '' }]} />
      {isLoading ?
        <>
          <SkeletonPlaceholder className={style.imagePlaceholder} />
          <SinglePageSkeleton />
        </>
        :
        <div className={style.wrapper}>
          <div className={style.social} ref={socialRef} />
          <div className={style.image}>
            <img src={`${BASE_URL}${data?.imagePath}`} alt="" />
          </div>
          <Title>{data?.title}</Title>
          <div className={style.content} dangerouslySetInnerHTML={{ __html: data?.text ?? '' }} />
        </div>
      }
    </Container>
  )
}

export default NewsItemPage;