import style from './Faq.module.scss'
import BreadCrumbs, { IBreadCrumb } from '../../share/BreadCrumbs/BreadCrumbs';
import QuestionItem from './Question/QuestionItem'
import Container from '../../UI/Container/Container';
import Title from '../../UI/Title/Title';
import { useEffect } from 'react';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';
import { useQuery } from 'react-query';
import { IFaq } from '../../../types/apiInterfaces';
import { getData } from '../../../utils/services';
import FaqSkeleton from './FaqSkeleton';

const Faq: React.FC = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['faq'],
    queryFn: (): Promise<IFaq[]> => getData('faq')
  })

  const path: IBreadCrumb[] = [{ name: 'Главная', link: '/' },
  { name: 'Часто задаваемые вопросы', link: '/faq' }]

  useEffect(() => {
    setDocumentTitle('Часто задаваемые вопросы')
  }, [])

  return (
    <Container>
      <BreadCrumbs links={path} />
      <Title>Часто задаваемые вопросы</Title>
      {isLoading ?
        <FaqSkeleton /> :
        <ul className={style.questions}>
          {data?.map((item, index) => <QuestionItem key={index}
            category={item.title}
            receptionFaqs={item.receptionFaqs}
            slug={item.slug} />)}
        </ul>
      }
    </Container>
  )
}

export default Faq;