import style from './Job.module.scss'
import { Link } from 'react-router-dom';
import BreadCrumbs, { IBreadCrumb } from '../../share/BreadCrumbs/BreadCrumbs';
import Loader from '../../share/Loader/Loader';
import Container from '../../UI/Container/Container';
import { useEffect } from 'react';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';
import { useQuery } from 'react-query';
import { IJob } from '../../../types/apiInterfaces';
import { getData } from '../../../utils/services';
import Button from '../../UI/Button/Button';
import JobSkeleton from './JobSkeleton';

const Job: React.FC = () => {
  const path: IBreadCrumb[] = [{ name: 'Главная', link: '/' },
  { name: 'Вакансии', link: '/job' }]

  const { data, isLoading } = useQuery({
    queryKey: ['job'],
    queryFn: (): Promise<IJob[]> => getData('job')
  })

  useEffect(() => {
    setDocumentTitle('Вакансии')
  }, [])

  return (
    <Container>
      <BreadCrumbs links={path} />
      <div className={style.wrapper}>
        <h2>Список вакансий</h2>
        {isLoading ? <JobSkeleton /> :
          <ul className={style.categoriesList}>
            {data?.map(item => <li key={item.created_at}>
              <h3>{item.title}</h3>
              <ul className={style.vacanciesList}>
                {item.receptionJobs.map(el => <li key={el.slug}><Link to={el.slug}>{el.title}</Link></li>)}
              </ul>
            </li>)}
          </ul>}
        <h2>Не нашли подходящей вакансии?</h2>
        <div className={style.bottomBlock}>
          <h3>Расскажите нам о себе, заполнив анкету</h3>
          <Link to="resume">
            <Button className={style.linkButton}>Заполнить</Button>
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default Job;