import SkeletonPlaceholder from '../../../UI/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './ThanksList.module.scss'

const ThanksListSkeleton: React.FC = () => {
  return <div className={style.thanksWrapper}>
    {[...Array(6)].map(() => {
      return <SkeletonPlaceholder className={style.thanksPlaceholder} />
    })}
  </div>
}

export default ThanksListSkeleton;