import { useParams } from 'react-router-dom';
import style from './FaqItem.module.scss'
import { Fragment, useEffect } from 'react';
import BreadCrumbs from '../../share/BreadCrumbs/BreadCrumbs';
import Container from '../../UI/Container/Container';
import { isArrayFull } from '../../../utils/isArrayFull/isArrayFull';
import Title from '../../UI/Title/Title';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';
import { useQuery } from 'react-query';
import { getData } from '../../../utils/services';
import { IFaq } from '../../../types/apiInterfaces';
import SinglePageSkeleton from '../../share/SinglePageSkeleton/SinglePageSkeleton';

const FaqItem: React.FC = () => {
  const slug = useParams().id;

  const { data, isLoading } = useQuery({
    queryKey: ['faq-item', slug],
    queryFn: (): Promise<IFaq[]> => getData(`faq/get-category/${slug}`)
  })

  useEffect(() => {
    if (isArrayFull(data)) {
      setDocumentTitle(data![0].title)
    }
  }, [data])

  return <Container>
    <BreadCrumbs links={[{ name: 'Главная', link: '/' },
    { name: 'Часто задаваемые вопросы', link: '/faq' }, { name: '', link: '' }]} />
    {isLoading ? <SinglePageSkeleton /> :
      isArrayFull(data) &&
      <div className={style.wrapper}>
        <Title>Раздел "{data![0].title}"</Title>
        <div className={style.mainText}>
          {data![0].receptionFaqs.map(({ answer, question }, index) => {
            return <Fragment key={index}>
              <h2>{question}</h2>
              <p dangerouslySetInnerHTML={{ __html: answer }}></p>
            </Fragment>
          })}
        </div>
      </div>
    }
  </Container>
}

export default FaqItem;