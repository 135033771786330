import style from './News.module.scss'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import NewsItem from '../../share/NewsItem/NewsItem'
import BreadCrumbs, { IBreadCrumb } from '../../share/BreadCrumbs/BreadCrumbs';
import Loader from '../../share/Loader/Loader';
import Pagination from '../../share/Pagination/Pagination';
import { formatPassedTime } from '../../../utils/formatPassedTime/formatPassedTime'
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';
import { useQuery } from 'react-query';
import { ApiDataResponseType, INews } from '../../../types/apiInterfaces';
import { getData } from '../../../utils/services';
import { classNames } from '../../../utils/classNames/classNames';
import Container from '../../UI/Container/Container';
import Title from '../../UI/Title/Title';
import NewsSkeleton from './NewsSkeleton';

const years: string[] = [];
const currentYear = new Date().getFullYear();
for (let i = 0; i < 7; i++) {
  years.push(String(currentYear - i))
}

const News: React.FC = () => {
  const path: IBreadCrumb[] = [{ name: 'Главная', link: '/' },
  { name: 'Новости', link: '/news' }];

  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get('page') || '1';
  const year = searchParams.get('year');

  const { data, isLoading } = useQuery({
    queryKey: ['news', year, page],
    queryFn: (): Promise<ApiDataResponseType<INews[]>> => getData('news', {
      models: false,
      searchParams: {
        page: page,
        year: year
      }
    })
  })

  const changePage = (page: number) => {
    if (page === 1) searchParams.delete('page');
    else searchParams.set('page', String(page));
    setSearchParams(searchParams)
  }

  const changeYear = (selectedYear: string) => {
    if (!selectedYear) {
      searchParams.delete('year');
    }
    else if (selectedYear === year) {
      searchParams.delete('year');
    }
    else searchParams.set('year', String(selectedYear));
    changePage(1);
    setSearchParams(searchParams)
  }

  useEffect(() => {
    setDocumentTitle('Новости')
  }, [])

  return (
    <main className={style.wrapper}>
      <Container>
        <BreadCrumbs links={path} />
        <Title>Дата публикации</Title>
        <div className={style.yearsWrapper}>
          {years.map(item => <button key={item}
            onClick={() => changeYear(item)}
            className={classNames(style.year, { [style.currenYear]: year === item })}>
            {item}
          </button>)}
        </div>
        {isLoading ?
          <NewsSkeleton /> :
          <>
            <div className={style.news}>
              {data?.models.length ?
                <NewsItem image={data.models[0].imagePath}
                  slug={data.models[0].slug}
                  title={data.models[0].title}
                  date={formatPassedTime(data.models[0].created_at, data.models[0].timeDifference)}
                  wrapperClass={style.firstItemWrapper}
                  descriptionClass={style.firstItemDescription} />
                : <p className={style.noNews}>За выбранный период публикаций не найдено</p>
              }
              {data?.models.slice(1).map(item => {
                return <NewsItem
                  key={item.created_at}
                  image={item.imagePath}
                  slug={item.slug}
                  title={item.title}
                  linkClass={style.newsPageLink}
                  date={formatPassedTime(item.created_at, item.timeDifference)}
                />
              })}
            </div>
            {
              data?.count && data?.count > 1 ?
                <Pagination activePage={Number(page)}
                  setActivePage={changePage}
                  numberOfPages={data.count}
                />
                : null
            }
          </>
        }
      </Container>
    </main>
  )
}

export default News;