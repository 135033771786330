import style from './ResumeForm.module.scss'
import {useState, useCallback, useEffect} from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormStatusType, IResumeForm } from '../../../../types/formInterfaces';
import { sendFormResume } from '../../../../utils/services';
import { useForm } from 'react-hook-form';
import Input from '../../../UI/Input/Input';
import FormSuccess from '../../../share/FormSuccess/FormSuccess';
import TextArea from '../../../UI/TextArea/TextArea';
import Button from '../../../UI/Button/Button';
import Loader from '../../../share/Loader/Loader';
import Select from '../../../UI/Select/Select';
import ErrorText from '../../../UI/ErrorText/ErrorText';

const ResumeForm: React.FC = () => {
  const [token, setToken] = useState<string>();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);
  const [status, setStatus] = useState<FormStatusType>(null);
  const [isSending, setIsSending] = useState(false)

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setRefreshReCaptcha(r => !r);
    }, 120000)
    return () => clearInterval(id)
  }, [])

  const { register,
    handleSubmit,
    formState: { errors, touchedFields },
    reset
  } = useForm<IResumeForm>({ mode: 'onBlur' });


  const onSubmit = async (data: IResumeForm) => {
    try {
      setIsSending(true)
      setStatus(null)
      const response = await sendFormResume(data, token ?? '');
      if (!response.ok) {
        throw new Error();
      }
      setRefreshReCaptcha(r => !r);
      reset();
      setStatus('success');
    }
    catch (e) {
      setStatus('error')
    }
    finally {
      setIsSending(false)
    }
  }

  return (
    <>
      <FormSuccess title='Анкета отправлена'
        description='Анкета отправлена на обработку. <br/>Ожидайте звонка.'
        isSent={status === 'success'}
      />
      <form onSubmit={handleSubmit(onSubmit)} className={style.form}>

        <div>
          <Input
            placeholder='Фамилия Имя Отчество'
            error={errors.name}
            touched={touchedFields.name}
            {...register('name', {
              required: 'Необходимо заполнить «Фамилия, Имя, Отчество».'
            })}
          />
          <p className={style.middleName}>Отчество при наличии в паспорте</p>
        </div>

        <Input
          error={errors.date_birth}
          touched={touchedFields.date_birth}
          label='Дата рождения'
          id='resume_birthdate'
          type='date'
          {...register('date_birth', {
            required: 'Необходимо заполнить «Дата рождения».'
          })}
        />

        <Input
          error={errors.phone}
          touched={touchedFields.phone}
          type="tel"
          mask="+7(999)999-99-99"
          placeholder='Контактный номер'
          {...register('phone', {
            required: 'Необходимо заполнить «Телефон».',
            pattern: /^[^_]*$/
          })}
        />

        <Input
          error={errors.email}
          touched={touchedFields.email}
          type='email'
          placeholder='Email'
          {...register('email', {
            required: 'Необходимо заполнить «E-mail».',
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Значение «E-mail» не является правильным email адресом.'
            }
          })}
        />

        <Input
          error={errors.job}
          touched={touchedFields.job}
          placeholder='Желаемая должность'
          {...register('job', {
            required: 'Необходимо заполнить «Желаемая должность».'
          })}
        />

        <Select
          error={errors.education}
          touched={touchedFields.education}
          {...register('education', {
            required: 'Необходимо заполнить «Уровень образования».'
          })}
        >
          <option value="">Уровень образования</option>
          <option value="Среднее">Среднее</option>
          <option value="Среднее специальное">Среднее специальное</option>
          <option value="Неоконченное высшее">Неоконченное высшее</option>
          <option value="Высшее">Высшее</option>
          <option value="Бакалавр">Бакалавр</option>
          <option value="Магистр">Магистр</option>
          <option value="Кандидат наук">Кандидат наук</option>
          <option value="Доктор наук">Доктор наук</option>
        </Select>

        <TextArea
          error={errors.experience}
          touched={touchedFields.experience}
          placeholder='Название организации, должность, период работы'
          label='Опыт работы за последние 3 года'
          {...register('experience', {
            required: 'Необходимо заполнить «Опыт работы за последние 3 года».'
          })}
        />

        <Input
          error={errors.language}
          touched={touchedFields.language}
          placeholder='Руский, Английский'
          label='Знание языков'
          {...register('language', {
            required: 'Необходимо заполнить «Знание иностранных языков».'
          })}
        />

        <TextArea
          error={errors.skill}
          touched={touchedFields.skill}
          placeholder='Перечислите Ваши навыки и знание компьютерных программ'
          label='Ключевые навыки'
          {...register('skill', {
            required: 'Необходимо заполнить «Ключевые навыки».'
          })}
        />

        <TextArea
          error={errors.about}
          touched={touchedFields.about}
          placeholder='Ваше хобби, личные качества, любая дополнительная информация, ссылки на портфолио'
          label='Информация о себе'
          {...register('about', {
            required: 'Необходимо заполнить «Информация о себе».'
          })}
        />

        <GoogleReCaptcha
          action='homepage'
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />

        {status === 'error' &&
          <ErrorText>Произошла ошибка при отправке формы. Попробуйте перезагрузить страницу или отправить форму позже снова</ErrorText>
        }

        <Button
          type='submit'
          className={style.submitBtn}
          disabled={isSending}
        >
          {isSending ?
            <>
              Отправка
              <Loader />
            </>
            :
            'Отправить'}
        </Button>
      </form>
    </>
  )
}

export default ResumeForm;
