import SkeletonPlaceholder from '../../../UI/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './ReviewSliderSkeleton.module.scss'

interface ReviewSliderSkeletonProps {
  type: 1 | 2
}

const ReviewSliderSkeleton: React.FC<ReviewSliderSkeletonProps> = ({ type }) => {
  return <div className={style.placeholderBlock}>
    {[...Array(type * 3)].map((_, index) => {
      return <div className={style.itemPlaceholder} key={index}>
        <SkeletonPlaceholder className={style.imagePlaceholder} />
        <SkeletonPlaceholder className={style.linkPlaceholder} />
      </div>
    })}
  </div>
}

export default ReviewSliderSkeleton;