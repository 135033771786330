import { useEffect } from 'react'
import BreadCrumbs, { IBreadCrumb } from '../../share/BreadCrumbs/BreadCrumbs';
import ResumeForm from './ResumeForm/ResumeForm';
import Container from '../../UI/Container/Container';
import Title from '../../UI/Title/Title';
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';

const Resume: React.FC = () => {
  const path: IBreadCrumb[] = [{ name: 'Главная', link: '/' },
  { name: 'Вакансии', link: '/job' },
  { name: 'Анкета', link: '/resume' }];

  useEffect(() => {
    setDocumentTitle('Заполнить анкету')
  }, [])

  return (
    <Container>
      <BreadCrumbs links={path} />
      <Title>Заполните анкету</Title>
      <ResumeForm />
    </Container>
  )
}

export default Resume;