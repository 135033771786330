import SkeletonPlaceholder from '../../UI/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './SinglePageSkeleton.module.scss'

const SinglePageSkeleton: React.FC = () => {
  return (
    <div className={style.wrapper}>
      <SkeletonPlaceholder className={style.titlePlaceholder} />
      <div className={style.textBlock}>
        <SkeletonPlaceholder className={style.textPlaceholder} />
        <SkeletonPlaceholder className={style.textPlaceholder} />
        <SkeletonPlaceholder className={style.textPlaceholder} />
        <SkeletonPlaceholder className={style.textPlaceholder} />
        <SkeletonPlaceholder className={style.textPlaceholder} />
      </div>
    </div>
  )
}

export default SinglePageSkeleton;